import React from "react"

import Button from "../components/button"
import SectionHeader from "./section-header"
import "../styles/convertkit-sera-form.css"

const CallToAction = () => (
  <div style={{ padding: "2rem 1rem", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
    <script src="https://f.convertkit.com/ckjs/ck.5.js"/>
    <form action="https://app.convertkit.com/forms/2471767/subscriptions" className="seva-form formkit-form"
          method="post" data-sv-form="2471767" data-uid="b1c3b29448" data-format="inline" data-version="5"
          data-options="{&quot;settings&quot;:{&quot;after_subscribe&quot;:{&quot;action&quot;:&quot;redirect&quot;,&quot;success_message&quot;:&quot;Success! Now check your email to confirm&quot;,&quot;redirect_url&quot;:&quot;https://unpublisher.co/waitlist-confirmation/&quot;},&quot;analytics&quot;:{&quot;google&quot;:null,&quot;facebook&quot;:null,&quot;segment&quot;:null,&quot;pinterest&quot;:null,&quot;sparkloop&quot;:null,&quot;googletagmanager&quot;:null},&quot;modal&quot;:{&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;powered_by&quot;:{&quot;show&quot;:true,&quot;url&quot;:&quot;https://convertkit.com?utm_campaign=poweredby&amp;utm_content=form&amp;utm_medium=referral&amp;utm_source=dynamic&quot;},&quot;recaptcha&quot;:{&quot;enabled&quot;:false},&quot;return_visitor&quot;:{&quot;action&quot;:&quot;hide&quot;,&quot;custom_content&quot;:&quot;You are already signed up for our waitlist! Thank you for your interest.&quot;},&quot;slide_in&quot;:{&quot;display_in&quot;:&quot;bottom_right&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;sticky_bar&quot;:{&quot;display_in&quot;:&quot;top&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15}},&quot;version&quot;:&quot;5&quot;}"
          min-width="400 500 600 700 800">
      <div data-style="clean">
        <ul className="formkit-alert formkit-alert-error" data-element="errors" data-group="alert"></ul>
        <div data-element="fields" data-stacked="false" className="seva-fields formkit-fields">
          <div className="formkit-field"><input className="formkit-input" name="email_address"
  aria-label="Email Address" placeholder="Email Address" required=""
  type="email"/>
          </div>
          <Button data-element="submit" >
            Join Waitlist
          </Button>
        </div>
      </div>
    </form>
  </div>
)

export default CallToAction
