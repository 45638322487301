import React from "react"
import PropTypes from "prop-types"

// import headerImage from "../images/dotted-transparent-bg.png"
import CallToAction from "./cta"

const Header = ({ siteTitle }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "4rem 1rem",
    }}
  >
    <h1 style={{ textAlign: "center" }}>Want a newsletter website <br/>that you 100% own?</h1>
    <p style={{ textAlign: "center", maxWidth: 440 }}>
      <strong>Ready for a website that does exactly what you want?</strong> We are building a solution that combines flexibility and automation to make your life easier
    </p>
    <ul><li><span><strong>Automated migration</strong> from Substack</span></li><li><span>Free hosting - <strong>no monthly fee</strong></span></li><li><span>Super-fast, <strong>SEO-optimized</strong> content</span></li><li><span>A solution that you<strong> 100% control</strong></span></li></ul>
    <CallToAction />
    {/*<div style={{ margin: 60, width: `250px`, position: "relative" }}>*/}
    {/*  <div style={{ clipPath: "inset(2% 5% round 2% 5%)" }}>*/}
    {/*    <MockupContent />*/}
    {/*  </div>*/}
    {/*  <div*/}
    {/*    style={{*/}
    {/*      position: "absolute",*/}
    {/*      width: "250px",*/}
    {/*      top: 0,*/}
    {/*    }}*/}
    {/*  >*/}
    {/*    <img*/}
    {/*      src={mockupFrame}*/}
    {/*      alt="outlines of shapes and confetti in the background "*/}
    {/*    />*/}
    {/*  </div>*/}
    {/*</div>*/}
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
